import Navbar from "./navbar/Navbar";
import classes from "./Layout.module.css";
import { createContext, useEffect, useRef, useState } from "react";
import Footer from "./footer/Footer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import ConsentModal from "./consent-modal/ConsentModal";

// Create context outside of the component
export const ModalContext = createContext();

const Layout = ({ children, notScrollable, isAuthLoading }) => {
  const rootRef = useRef(document.getElementById("root"));
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const [cookieModal, setCookieModal] = useState(false);

  // Add the noScroll class on the root div when displaying a modal
  useEffect(() => {
    if (notScrollable) {
      rootRef.current.classList.add(classes.noScroll);
    } else if (rootRef.current.classList.contains(classes.noScroll)) {
      rootRef.current.classList.remove(classes.noScroll);
    }
  }, [notScrollable]);

  const location = useLocation();

  const loginOrSignupOrForgotPassword =
    location.pathname.includes("login") ||
    location.pathname.includes("signup") ||
    location.pathname.includes("forgot-password");

  // const isHomePage = location.pathname === "/";

  const badgeElement = document.querySelector(".grecaptcha-badge");

  useEffect(() => {
    //change the visibility of badge for required urls
    if (badgeElement) {
      badgeElement.style.visibility = loginOrSignupOrForgotPassword
        ? "visible"
        : "hidden";
    }
  }, [location, badgeElement]);

  const { language, direction } = useSelector((state) => state.language);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log("cookie modal value", cookieModal);

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    console.log("cookies 888", consent);
    //here checking only whether cookieConsent has some value.
    if (!consent) {
      setCookieModal(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", true, {
      secure: true, // Ensures the cookie is only transmitted over HTTPS
      path: "/", // Makes the cookie available across the entire website
      expires: 730,
    });
    setCookieModal(false);
  };

  const handleReject = () => {
    Cookies.set("cookieConsent", false, { expires: 30 });
    setCookieModal(false);
  };

  return (
    <div dir={direction}>
      <>
        {/* {!isHomePage && ( */}
        <nav className={classes.navbar}>
          <Navbar isAuthLoading={isAuthLoading} />
        </nav>
        {/*  )} */}
        {/* {modalIsVisible && ( */}
        {isModalOpen && <div className="overlay-div"></div>}
        <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
          {/* <main
            className={`${classes.mainContent} ${
              isHomePage ? classes.comingSoonContent : ""
            }`}
          > */}
          <main className={`${classes.mainContent}`}>{children}</main>
        </ModalContext.Provider>
        {/* {!isHomePage && ( */}
        <footer className={classes.footer}>
          <Footer language={language} direction={direction} />
        </footer>
        {/* )} */}
      </>
      {cookieModal && (
        <ConsentModal handleAccept={handleAccept} handleReject={handleReject} />
      )}
    </div>
  );
};

export default Layout;
