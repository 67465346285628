

let dev = {
  API_ENDPOINT_URL:
    ''
}
let prod = {
  API_ENDPOINT_URL:
    ''
}
let test = {
  API_ENDPOINT_URL:
    ''
}


if (process.env.REACT_APP_MODE === "Dev") {
  //development
  dev = {
    API_ENDPOINT_URL: 'https://newsapi.24liveyard.com/api/v1/',
  };

  prod = {
    API_ENDPOINT_URL: 'https://newsapi.24liveyard.com/api/v1/',
  };

  test = {
    API_ENDPOINT_URL: 'https://newsapi.24liveyard.com/api/v1/',
  };
} else
  if (process.env.REACT_APP_MODE === "userDev") {
    //development-uat
    dev = {
      API_ENDPOINT_URL: 'https://apiuat.24liveyard.com/api/v1/',
    };

    prod = {
      API_ENDPOINT_URL: 'https://apiuat.24liveyard.com/api/v1/',
    };

    test = {
      API_ENDPOINT_URL: 'https://apiuat.24liveyard.com/api/v1/',
    };
  } else {

    // live
    dev = {
      API_ENDPOINT_URL: 'https://api.oumuamua.news/api/v1/',
    };

    prod = {
      API_ENDPOINT_URL: 'https://api.oumuamua.news/api/v1/',
    };

    test = {
      API_ENDPOINT_URL: 'https://api.oumuamua.news/api/v1/',
    };
  }


const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
