import { useEffect, useRef, useState } from "react";

import { emailIsValid } from "validators/authValidation";

import Button from "components/UI/button/Button";
import MailIcon from "components/UI/icons/MailIcon";
import InputWithLabel from "components/UI/forms/input/InputWithLabel";

import classes from "./SignupForm.module.css";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import { useDispatch, useSelector } from "react-redux";

import { useMutation, useQuery } from "@tanstack/react-query";

import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import {
  API_BASE_URL,
  APP_PREFIX_PATH,
  REACT_APP_DEV_MODE,
} from "configs/AppConfig";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Upload,
  message,
  notification,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  updateProfile,
} from "firebase/auth";
import { auth } from "configs/FirebaseConfig";
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import getErrorMessage from "helpers/getErrorMessage";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import { PolicyDataKey } from "helpers/queriesDataKeys";
import useWindowWidth from "helpers/useWindowWidth";
import AdjustFontSize from "hooks/AdjustFontSize";
import CheckboxInput from "./CheckboxInput";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";

const rules = {
  required: [
    {
      required: true,
      message: "Required",
    },
  ],
  firstName: [
    {
      required: true,
      message: "Required",
    },
    {
      pattern: /^[a-zA-Z0-9 ]+$/,
      message: "First Name can only include letters, numbers, spaces!",
    },
  ],
  lastName: [
    {
      required: true,
      message: "Required",
    },
    {
      pattern: /^[a-zA-Z0-9 ]+$/,
      message: "Last Name can only include letters, numbers, spaces!",
    },
  ],
  middleName: [
    {
      required: false,
      message: "Required",
    },
    {
      pattern: /^[a-zA-Z0-9 ]+$/,
      message: "Name can only include letters, numbers, spaces!",
    },
  ],
  nickName: [
    {
      required: false,
      message: "Required",
    },
    {
      pattern: /^[a-zA-Z0-9 ]+$/,
      message: "Nickname can only include letters, numbers, spaces!",
    },
  ],
  email: [
    {
      required: true,
      message: "Required",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input your password",
    },
    {
      min: 16,
      message: "Password must have at least 16 characters",
    },
    // {
    //   pattern: new RegExp(
    //     "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*])(?=.{8,})"
    //   ),
    //   message:
    //     "Password must have atleast 8 characters with one lowercase,one uppercase,one number,one special character",
    // },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const email = getFieldValue("email");
        if (value && email && value === email) {
          return Promise.reject(
            new Error("Password cannot be the same as the email address.")
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  confirm: [
    {
      required: true,
      message: "Please confirm your password!",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords do not match!");
      },
    }),
  ],
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const SignupForm = ({
  isPage = false,
  redirect = false,
  redirectRoute = "",
}) => {
  const [userCreating, setUserCreating] = useState(false);
  const [form] = Form.useForm();
  const { constants } = useSelector((state) => state.constants);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [images, setImages] = useState([]);
  const user = useSelector((state) => state.user);
  const [registrationStep, setRegistrationStep] = useState("form");
  const [formValues, setFormValues] = useState();
  const contentRef = useRef(null);

  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState(null);
  const [avatarImages, setAvatarImages] = useState([]);
  const [removingAvatarImage, setRemovingAvatarImage] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const { Option } = Select;
  const { language, direction } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const width = useWindowWidth();

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [recaptchaVerifySuccess, setRecaptchaVerifySuccess] = useState(null);
  const [recaptchaCheckLoading, setRecaptchaCheckLoading] = useState(false);

  const [responsiveHtml, setResponsiveHtml] = useState("");
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const {
    isLoading: isTermsLoading,
    isError,
    data: termsofService,
    error,
  } = useQuery(
    [PolicyDataKey, location.pathname],
    async () => {
      const response = await fetch(
        `${API_BASE_URL}information-policy/getBySlug/copyright-agreement/public`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("data articles 222", data);

      if (!response.ok) {
        console.log("Error", data);
        if (Array.isArray(data.message)) {
          data.message.forEach((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });
        } else {
          console.log("entered here 222");
          notification.error({
            message: t("error"),
            description: data.message ? data.message : "An error occured.",
          });
        }
        throw new Error(data.message);
      }
      return data.data;
    },
    {
      retry: 0,
      // retryDelay: 1000,
      // refetchOnWindowFocus: false,
    }
  );

  console.log("termsLoading", isTermsLoading);

  useEffect(() => {
    //html content will rearrange fontsize when window width changes
    if (termsofService) {
      setResponsiveHtml(
        AdjustFontSize(
          termsofService[language]?.content
            ? termsofService[language]?.content
            : termsofService["en"]?.content ||
                termsofService["ar"]?.content ||
                termsofService["he"]?.content,
          width
        )
      );
    }
  }, [termsofService, width, language]);

  const handleLogout = async () => {
    await signOut(auth);
    dispatch(userActions.logout());
    notification.error({
      message: t("error"),
      description: "Unauthorized.",
    });
    navigate("/");
    return;
  };

  // Function to load reCAPTCHA script dynamically
  const loadRecaptchaScript = () => {
    return new Promise((resolve, reject) => {
      if (
        document.querySelector(
          `script[src="https://www.google.com/recaptcha/api.js?render=${
            REACT_APP_DEV_MODE == "Dev"
              ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
              : REACT_APP_DEV_MODE == "userDev"
              ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_USER_TEST
              : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
          }"]`
        )
      ) {
        resolve(); // Script is already loaded
      } else {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${
          REACT_APP_DEV_MODE == "Dev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
            : REACT_APP_DEV_MODE == "userDev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_USER_TEST
            : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE
        }`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });
  };

  const recaptchaVerification = async () => {
    if (window.grecaptcha) {
      try {
        // Generate the reCAPTCHA v3 token
        const token = await window.grecaptcha.execute(
          REACT_APP_DEV_MODE == "Dev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST
            : REACT_APP_DEV_MODE == "userDev"
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY_USER_TEST
            : process.env.REACT_APP_RECAPTCHA_SITE_KEY_LIVE,
          { action: "signin" }
        );
        setRecaptchaToken(token);
        setRecaptchaCheckLoading(true);

        // Verify the token with your backend (if applicable)
        const tokenResponse = await fetch(`${API_BASE_URL}recaptcha/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ token }),
        });

        const tokenResult = await tokenResponse.json();

        if (tokenResult.success) {
          setRecaptchaCheckLoading(false);
          setRecaptchaVerifySuccess(true);
        } else {
          setRecaptchaCheckLoading(false);
          setRecaptchaVerifySuccess(false);
        }
      } catch (error) {
        setRecaptchaCheckLoading(false);
        setRecaptchaVerifySuccess(false);
        console.error("Error during sign-in:", error);
      }
    } else {
      notification.error({
        message: "Error",
        description: "reCAPTCHA is not loaded. Please try again later.",
      });
      await loadRecaptchaScript();
    }
  };

  useEffect(() => {
    recaptchaVerification();
  }, []);

  const shiftToSignIn = () => {
    if (location.pathname.includes("signup")) {
      navigate(`/login?redirectRoute=${redirectRoute}`);
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }
  };

  const submitCreateUser = async () => {
    // e.preventDefault();

    if (recaptchaVerifySuccess == false || !recaptchaVerifySuccess) {
      notification.error({
        message: "Error",
        description: "reCAPTCHA check failed.",
      });
      return;
    }

    form
      .validateFields()
      .then(async (values) => {
        console.log("the values are", values);
        setRegistrationStep("agree");
        setFormValues(values);
      })
      .catch((info) => {
        console.log("error in form", info);
        setUserCreating(false);
        message.error("Please check all input fields");
      });
  };

  const completeRegistration = async () => {
    let values = formValues;

    setUserCreating(true);
    //creating user in firebase
    let userCredential = {};
    dispatch(userActions.updateItsCreatingUser());
    try {
      userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
    } catch (error) {
      setUserCreating(false);
      dispatch(userActions.updateNotCreatingUser());
      console.log("firebase error", error);
      notification.error({
        message: "Error",
        description: getErrorMessage(error.code),
      });
      return; //to avoid propogation to catch fn below
      // throw new Error(error);
    }
    const user = userCredential.user;
    // if (user && previewUrl) {
    //   try {
    //     // Update the user's profile photoURL with a custom image URL
    //     await updateProfile(user, {
    //       photoURL: previewUrl,
    //     });

    //     console.log("Profile picture updated:", user.photoURL);
    //   } catch (error) {
    //     console.error("Error updating profile picture:", error);
    //   }
    // }
    console.log("user data from firebase", user);

    //refresh token after new user registered to get all data in token
    const idTokenResult = await auth.currentUser?.getIdToken(true);

    // notification.success({
    //   message: t("success"),
    //   description: "Creating User...",
    //   // style: { direction },
    // });

    if (values.phoneNumber && !values.phoneNumber.startsWith("+")) {
      values.phoneNumber = `+${values.phoneNumber}`;
    }

    //creating user file in server
    const sendingValues = {
      firstName: values.firstName,
      ...(values.middleName && { middleName: values.middleName }),
      ...(values.nickName && { nickName: values.nickName }),
      ...(previewUrl && { profileImage: previewUrl }),
      ...(avatarPreviewUrl && { avatarImage: avatarPreviewUrl }),
      // middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      customerType: values.customerType,
      phoneNumber: values.phoneNumber,
      // phoneNumber: `+${values.phoneNumber}`,
    };
    const sendingValuesFiltered = _.mapValues(sendingValues, (value) =>
      value === "" ? null : value
    );
    console.log("sendingValuesFiltered", sendingValuesFiltered);

    const response = await fetch(
      `${API_BASE_URL}users/register-firebase-user`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify(sendingValuesFiltered),
      }
    );

    const data = await response.json();
    console.log("data from server api", data);

    if (data.statusCode == 401) {
      handleLogout();
    }

    if (!response.ok) {
      console.log("Error", data);
      dispatch(userActions.logout());
      await signOut(auth); //to avoid logged in condition from firebase eventhough user create api failed
      if (Array.isArray(data.message)) {
        data.message.forEach((error) => {
          notification.error({
            message: t("error"),
            description: error,
          });
        });
      } else {
        console.log("entered here 222");
        notification.error({
          message: t("error"),
          description: data.message ? data.message : "An error occured",
        });
      }
      setUserCreating(false);
      return;
      // throw new Error(data.message);
    }
    if (data.success) {
      form.resetFields();

      console.log("777 auth currentuser", auth.currentUser);

      console.log("id token 333", idTokenResult);
      dispatch(userActions.updateAccessToken(idTokenResult));
      // notification.success({
      //   message: t("success"),
      //   description: "User created successfully.",
      //   style: { direction },
      // });

      try {
        await sendEmailVerification(user);
        notification.success({
          // message: "Verification email",
          description:
            "A verification message has been sent to the email provided from info@oumuamua.news.  If you cannot find the message, please check your SPAM or JUNK folder and be sure to allow emails from Oumuamua.news.",
        });
      } catch (verificationError) {
        notification.error({
          message: "Failed to send verification email",
          description: verificationError.message,
        });
      }

      await signOut(auth);
      dispatch(userActions.logout());
      shiftToSignIn();
    }
    setUserCreating(false);
  };

  function formatString(str) {
    // Capitalize the first letter
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    // Add a space before capital letters (for camelCase strings)
    const spaced = capitalized.replace(/([a-z])([A-Z])/g, "$1 $2");

    return spaced;
  }

  const onRemoveAvater = (e) => {
    e.preventDefault();
    if (!previewUrl) {
      notification.error({
        message: "Error",
        description: "Profile image not added.",
      });
      return;
    }
    setPreviewUrl(null);
  };

  const {
    isLoading: isImageUploading,
    // isError: isSubmitError,
    mutateAsync: uploadImages,
  } = useMutation(async (formData) => {
    const bodyFormData = new FormData();
    formData.forEach((img) => {
      bodyFormData.append("files", img);
    });
    const response = await fetch(`${API_BASE_URL}filemanager/images/upload`, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: bodyFormData,
    });

    const data = await response.json();
    console.log("added imageUrls 222", data);

    if (data.statusCode == 401) {
      handleLogout();
    }

    if (!response.ok) {
      console.log("Error", data);
      // if (data.message.length > 0) {
      //   data.message.map((error) => {
      //     notification.error({
      //       message: t("error"),
      //       description: error,
      //     });
      //   });
      // } else {
      //   notification.error({
      //     message: t("error"),
      //     description: data.message ? data.message : "An error occured",
      //   });
      // }
      throw new Error(data.message);
    }

    return data.fileUrls;
  });

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const uploadToAWS = async (file) => {
    console.log("file to upload", file);

    let url = null;

    if (!validURL(file?.url)) {
      url = await uploadImages([file.originFileObj]);

      console.log("uploaded file and got url", url);
      return url[0];
    } else return file.url;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewUrl(file.url || file.preview);
  };

  const handleChange = async ({ fileList }) => {
    setImages(fileList.slice(-1));
    if (fileList.length > 0) {
      const imageObj = fileList.slice(-1)[0];
      const responseUrl = await uploadToAWS(imageObj);
      setPreviewUrl(responseUrl);
    }
    // form.validateFields(["image"]);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => contentRef?.current,
  // });

  const handleDownloadPDF = async () => {
    setPdfLoading(true);
    if (contentRef.current) {
      const element = contentRef.current;

      await html2pdf()
        .set({
          margin: 20,
          filename: "Oumuamua News Copyright Agreement.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true }, // Increase scale for better quality
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          // pagebreak: {
          //   before: ".page-break-before", // Trigger page break before elements with this class
          //   avoid: ".avoid-page-break", // Avoid breaking content inside elements with this class
          //   // mode: 'css',            // Avoid breaking content when it can fit on the same page
          //   mode: "avoid-all", // Avoid breaking content when it can fit on the same page
          // },
        })
        .from(element)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            // pdf.text(
            //   "Oumuamua News - Agreement",
            //   10,
            //   pdf.internal.pageSize.height - 10
            // );
          }
        })
        .save();
    }
    setPdfLoading(false);
  };

  //Avatar image functions
  const {
    isLoading: isAvatarUploading,
    // isError: isSubmitError,
    mutateAsync: uploadAvatarImages,
  } = useMutation(async (formData) => {
    const bodyFormData = new FormData();
    formData.forEach((img) => {
      bodyFormData.append("files", img);
    });
    const response = await fetch(`${API_BASE_URL}filemanager/images/upload`, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: bodyFormData,
    });

    const data = await response.json();
    console.log("added imageUrls 222", data);

    if (data.statusCode == 401) {
      handleLogout();
      return;
    }

    if (!response.ok) {
      console.log("Error", data);
      throw new Error(data.message);
    }

    return data.fileUrls;
  });

  const uploadAvatarToAWS = async (file) => {
    console.log("file to upload", file);

    let url = null;

    if (!validURL(file?.url)) {
      url = await uploadAvatarImages([file.originFileObj]);

      console.log("uploaded file and got url", url);
      return url[0];
    } else return file.url;
  };

  const handleAvatarPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setAvatarPreviewUrl(file.url || file.preview);
  };

  const handleAvatarChange = async ({ fileList }) => {
    setAvatarImages(fileList.slice(-1));
    if (fileList.length > 0) {
      const imageObj = fileList.slice(-1)[0];
      const responseUrl = await uploadAvatarToAWS(imageObj);
      setAvatarPreviewUrl(responseUrl);
    }
  };

  const handleBeforeUpload = (file) => {
    const isValidFormat = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
      "image/bmp",
      "image/webp",
      "image/svg+xml",
    ].includes(file.type);
    if (!isValidFormat) {
      notification.error({
        message: "Invalid file type",
        description:
          "You can only upload JPG, PNG, GIF, BMP, WEBP, or SVG files.",
      });
      return Upload.LIST_IGNORE; // Prevent the file from being added to the upload list
    }
    return false; // Allow the file to be added to the upload list and dont call api instantly
  };

  const onRemoveAvatar = async (e) => {
    e.preventDefault();
    if (!avatarPreviewUrl) {
      notification.error({
        message: "Error",
        description: "Avatar not added.",
      });
      return;
    }
    setAvatarPreviewUrl(null);
  };

  return (
    // <form
    //   dir={direction}
    //   className={classes.signUpForm}
    //   onSubmit={onSumbitFormHandler}
    // >
    <>
      {registrationStep == "form" && (
        <Form
          layout="vertical"
          form={form}
          dir={direction}
          name="filter_form"
          className={`ant-advanced-search-form w-100 connectForm custom-register-form-class ${classes.signUpForm}`}
        >
          <h1>{t("register_here")}</h1>
          <div className={classes.profileImagesContainer}>
            {/* Profile image */}
            <div className={classes.profileContainer}>
              <Avatar size={70} src={previewUrl} icon={<UserOutlined />} />
              <div className={classes.labelsDiv}>
                <h3 className={classes.imageHeading}>{t("profile_photo")}</h3>
                <div className={classes.imageButtonsDiv}>
                  <Upload
                    name="image"
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/webp, image/svg+xml"
                    fileList={images}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    multiple={false}
                    beforeUpload={handleBeforeUpload} // Validate file format before uploading
                    // beforeUpload={() => false} //not to trigger upload when selecting images
                    showUploadList={false}
                  >
                    <Button
                      className={classes.submitButton}
                      onClick={(e) => e.preventDefault()}
                      disabled={isImageUploading}
                    >
                      {isImageUploading && <LoadingOutlined />}
                      {previewUrl ? t("change") : t("add")}
                    </Button>
                  </Upload>
                  <Button
                    className={classes.cancelButton}
                    onClick={onRemoveAvater}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              </div>
              {/* <img
          className={classes.profileIcon}
          src="/news-img/icons/profile-icon.svg"
          alt=""
        /> */}
            </div>
            {/* Avatar image */}
            <div className={classes.profileContainer}>
              <Avatar
                size={70}
                src={avatarPreviewUrl}
                icon={<UserOutlined />}
              />
              <div className={classes.labelsDiv}>
                <h3 className={classes.imageHeading}>{t("avatar")}</h3>
                <div className={classes.imageButtonsDiv}>
                  <Upload
                    name="image"
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/webp, image/svg+xml"
                    fileList={avatarImages}
                    onPreview={handleAvatarPreview}
                    onChange={handleAvatarChange}
                    multiple={false}
                    beforeUpload={handleBeforeUpload} // Validate file format before uploading
                    // beforeUpload={() => false} //not to trigger upload when selecting images
                    showUploadList={false}
                  >
                    <Button
                      className={classes.submitButton}
                      onClick={(e) => e.preventDefault()}
                      disabled={isAvatarUploading}
                    >
                      {!removingAvatarImage && isAvatarUploading && (
                        <LoadingOutlined />
                      )}
                      {avatarPreviewUrl ? t("change") : t("add")}
                    </Button>
                  </Upload>
                  <Button
                    className={classes.cancelButton}
                    onClick={onRemoveAvatar}
                  >
                    <DeleteOutlined />

                    {/* {t("remove")} */}
                  </Button>
                </div>
              </div>
              {/* <img
          className={classes.profileIcon}
          src="/news-img/icons/profile-icon.svg"
          alt=""
        /> */}
            </div>
          </div>

          <div className={classes.inputField}>
            <span className={classes.inputLabel}>{t("registering_as")}</span>
            <Form.Item name="customerType" rules={rules.required}>
              <Select
                // className="register-select"
                // defaultValue="option1"
                // showSearch
                // filterOption={(input, option) =>
                //   option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                // optionFilterProp="label"
                // onChange={handleChange}
                placeholder={t("individual_or_organization")}
                suffixIcon={
                  <img
                    src="/news-img/icons/antd-arrow.svg"
                    alt="custom arrow"
                    className={classes.customArrow}
                  />
                }
              >
                <Option value={"individual"}>Individual</Option>
                <Option value={"organisation"}>Organisation</Option>
                {/* {constants?.Customertype?.map((type) => (
                  <Option value={type}>{formatString(type)}</Option>
                ))} */}
              </Select>
            </Form.Item>
          </div>
          <div className={classes.inputField}>
            <span className={classes.inputLabel}>{t("first_name")}</span>
            <Form.Item name="firstName" rules={rules.firstName}>
              <Input
                placeholder={t("first_name")}
                // className="signInInput"
                // value={name}
                // onChange={(e) => setName(e.target.value)}
                // onBlur={checkNameValid}
              />
            </Form.Item>
          </div>
          <div className={classes.inputField}>
            <span className={classes.inputLabelOptional}>
              {t("middle_name")}
            </span>
            <Form.Item name="middleName" rules={rules.middleName}>
              <Input
                placeholder={t("middle_name")}
                // className="signInInput"
                // value={name}
                // onChange={(e) => setMiddleName(e.target.value)}
                // onBlur={checkNameValid}
              />
            </Form.Item>
          </div>
          <div className={classes.inputField}>
            <span className={classes.inputLabel}>{t("last_name")}</span>
            <Form.Item name="lastName" rules={rules.lastName}>
              <Input
                placeholder={t("last_name")}
                // className="signInInput"
                // value={name}
                // onChange={(e) => setLastName(e.target.value)}
                // onBlur={checkNameValid}
              />
            </Form.Item>
          </div>
          <div className={classes.inputField}>
            <span className={classes.inputLabelOptional}>{t("nickname")}</span>
            <Form.Item name="nickName" rules={rules.nickName}>
              <Input
                placeholder={t("nickname")}
                // className="signInInput"
                // value={name}
                // onChange={(e) => setLastName(e.target.value)}
                // onBlur={checkNameValid}
              />
            </Form.Item>
          </div>
          <div className={classes.inputField}>
            <span className={classes.inputLabel}>{t("email")}</span>
            <Form.Item name="email" rules={rules.email}>
              <Input
                placeholder={t("email")}
                // className="signInInput"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                // onBlur={checkEmailValid}
              />
            </Form.Item>
            {/* <p className={classes.inputBottom}>{t("confirmation_email")}</p> */}
          </div>
          <div className={classes.inputField}>
            <span className={classes.inputLabelOptional}>
              {t("phone_number")}
            </span>
            <Form.Item
              name="phoneNumber"
              // rules={rules.required}
              className="register-phone-input"
            >
              <PhoneInput
                // inputProps={{
                //   name: "mobileNumber",
                // }}
                dir={direction}
                country={"us"}
                // value={mobileNumber}
                // onChange={(value) => setMobileNumber(`+${value}`)}
                // parentClass={"register-phone-input"}
                // dropdownStyle={{ width: "50px" }}
                enableSearch={true}
                specialLabel=""
                disableDropdown={false}
                countryCodeEditable={true}
                // placeholder={t("enter_phone")}
              />
            </Form.Item>
          </div>

          <div className={classes.inputField}>
            {/* <span className={classes.inputLabel}>{t("password")}</span> */}
            <Tooltip
              placement="top"
              title={t("enter_password_with_16_characters")}
            >
              <span className={`${classes.inputLabel} fitContent`}>
                {t("password")} <InfoCircleOutlined />
              </span>
            </Tooltip>
            <Form.Item name="password" rules={rules.password}>
              <Input.Password
                placeholder={t("type_your_password")}
                // className="signInInput2"
                // onChange={(e) => setPassword(e.target.value)}
                // onBlur={checkPasswordValid}
              />
            </Form.Item>
            {/* <p className={classes.inputBottom}>{t("password_rule")}</p> */}
          </div>
          <div className={classes.passwordField}>
            <span className={classes.inputLabel}>
              {" "}
              {t("reenter_password")}{" "}
            </span>

            <Form.Item name="confirm" rules={rules.confirm}>
              <Input.Password
                placeholder={t("reenter_password")}
                // className="signInInput2"
                // onChange={handleConfirmPasswordChange}
                // onBlur={checkPasswordValid}
              />
            </Form.Item>
          </div>
          <div className={classes.recaptchaContent}>
            {recaptchaCheckLoading && (
              <div className={classes.checking}>
                <LoadingOutlined
                  style={{ width: "14px", color: "blue", fontWeight: "700" }}
                />
                Verifying reCAPTCHA...
              </div>
            )}
            {!recaptchaCheckLoading && recaptchaVerifySuccess && (
              <div className={classes.checkSuccess}>
                <CheckCircleOutlined
                  style={{ width: "16px", fontWeight: "700" }}
                />
                reCAPTCHA verification successful!
              </div>
            )}
            {!recaptchaCheckLoading && recaptchaVerifySuccess == false && (
              <div className={classes.checkFailed}>
                <CloseCircleOutlined
                  style={{ width: "16px", marginRight: "5px", color: "red" }}
                />
                reCAPTCHA verification failed!
                <span
                  className={classes.tryAgain}
                  onClick={() => recaptchaVerification()}
                >
                  Try Again
                </span>
              </div>
            )}
          </div>
          <div className={classes.buttonContainer}>
            <button
              className={classes.signUpButton}
              onClick={() => submitCreateUser()}
            >
              {userCreating && (
                <LoadingOutlined
                  style={{ width: "15px", marginRight: "5px" }}
                />
              )}
              {t("continue")}
            </button>
          </div>
          <section className={classes.signUpFooter}>
            <p>
              {t("already_user")} ?
              <span className={classes.signUpLink} onClick={shiftToSignIn}>
                &nbsp;{t("login")}
              </span>
            </p>
          </section>
          {/* </form> */}
        </Form>
      )}
      {registrationStep == "agree" && (
        <>
          <div className={classes.agreeContainer}>
            <h1 className={classes.agreeHeading}>{t("copyright_agreement")}</h1>
            <div className={classes.contentDiv}>
              {isTermsLoading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoadingOutlined
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      fontWeight: "700",
                      marginTop: "10px",
                    }}
                  />
                </div>
              )}
              {!isTermsLoading && termsofService && (
                <>
                  <div style={{ display: "none" }}>
                    <div ref={contentRef}>
                      <h1 className={`${classes.agreeHeading}`}>
                        {t("copyright_agreement")}
                      </h1>
                      <div
                        className={`${classes.description} agreementContent`}
                        dangerouslySetInnerHTML={{
                          __html: responsiveHtml,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={`${classes.description} agreementContent`}
                    dangerouslySetInnerHTML={{
                      __html: responsiveHtml,
                    }}
                  ></div>
                </>
              )}
            </div>
            <div className={classes.checkboxDiv}>
              <CheckboxInput
                id="agreement"
                name="agreement"
                // label={t("remember_me")}
                className={classes.checkBoxInput}
                // value={isAgreementChecked}
                // isCircle
                onClick={() => setIsAgreementChecked((prevState) => !prevState)}
              />
              <h4 className={classes.agreeMessage}>
                By subscribing to Oumuamua News platform you agree to our{" "}
                <a
                  href="/informations/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
                ,{" "}
                <a
                  href="/informations/oumuamua-terms-of-service-overview"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Services
                </a>{" "}
                and all related published policies on Oumuamua.news.
              </h4>
            </div>
            <div className={classes.buttonsDiv}>
              <Button
                className={classes.printButton}
                // onClick={() => handlePrint()}
                onClick={() => handleDownloadPDF()}
              >
                {!pdfLoading && (
                  <img
                    src="/news-img/icons/print.svg"
                    alt=""
                    className={classes.printIcon}
                  />
                )}
                {pdfLoading && (
                  <LoadingOutlined
                    style={{ width: "15px", marginRight: "5px" }}
                  />
                )}
                {t("print")}
              </Button>
              <div className={classes.acceptDeclineDiv}>
                <Button
                  className={classes.acceptButton}
                  onClick={() => completeRegistration()}
                  disabled={!isAgreementChecked}
                >
                  {userCreating && (
                    <LoadingOutlined
                      style={{ width: "15px", marginRight: "5px" }}
                    />
                  )}
                  {t("accept")}
                </Button>
                <Button
                  className={classes.declineButton}
                  onClick={() => setRegistrationStep("declined")}
                >
                  {t("decline")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      {registrationStep == "declined" && (
        <>
          <div className={classes.agreeContainer}>
            <p className={classes.declineMessage}>{t("subscription_error")}</p>
            <Button
              className={classes.okayButton}
              onClick={() => {
                setRegistrationStep("form");
                // form.resetFields();
                // if (previewUrl) setPreviewUrl(null);
                setIsAgreementChecked(false);
              }}
            >
              {t("okay")}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default SignupForm;
