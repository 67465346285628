import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import SpinnerForButton from "../loading-spinner/SpinnerForButton";
import SpinnerForButton2 from "../loading-spinner/SpinnerForButton2";
import classes from "./Button.module.css";

const btnClass = {
  colored: classes.colored,
  uncolored: classes.uncolored,
  coloredGray: classes.coloredGray,
  coloredGreen: classes.coloredGreen,
  coloredBlack: classes.coloredBlack,
};

const Button = ({
  className,
  onClick,
  children,
  type,
  option,
  disabled,
  loading,
  ref,
}) => {
  return (
    <button
      ref={ref}
      type={type}
      onClick={onClick}
      className={`${classes.button} ${btnClass[option]} ${className}`}
      disabled={disabled}
    >
      {/*black spinner */}
      {loading && option === "uncolored" && <SpinnerForButton2 />}
      {/*white spinner */}
      {loading && option !== "uncolored" && <SpinnerForButton />}
      {children}
    </button>
  );
};

export default Button;
