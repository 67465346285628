import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";

import SignupForm from "components/forms/sign-up/SignupForm";
import { Modal } from "antd";
import ModalHeader from "components/UI/modal/ModalHeader";
// import Button from 'components/UI/button/Button';
// import GoogleIcon from 'components/UI/icons/GoogleIcon';
// import FacebookIcon from 'components/UI/icons/FacebookIcon';
// import TwitterIcon from 'components/UI/icons/TwitterIcon';
import classes from "./Signup.module.css";
import { useState } from "react";

const Signup = ({
  onClose,
  // showSignupDetailsModal,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);

  const signInClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);

    // toggle the visibility of the Modal
    dispatch(modalActions.toggleModal());
    //to set input element value to buyer last bid
  };

  return (
    <>
      <div className="modal-overlay" />
      <Modal
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={`${classes.signUpModal} signup-modal`}
        footer={null}
        centered
        closeIcon={
          <img
            src={`/news-img/icons/close-icon.svg`}
            alt="Close Icon"
            className={classes.modalClose}
          />
        } // Custom close icon as an image
      >
        <SignupForm
        // onSumbitForm={showSignupDetailsModal}
        />
      </Modal>
    </>
  );
};

export default Signup;
